import React, { ReactNode } from "react";
import { Oval } from 'react-loading-icons';
import * as B from "./Button.styles";

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  icon?: ReactNode;
  variant: "primary" | "secondary";
  onClick?: () => void;
  height?: string;
  width?: string;
  radius?: string;
  size?: string;
  children?: React.ReactNode;
  label?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  icon,
  variant,
  onClick,
  height,
  width,
  radius,
  size,
  children,
  label,
  isLoading,
  hidden
}) => {

  return (
    <B.Body onClick={onClick} disabled={isLoading} style={{display: hidden ? 'none' : 'block'}} >
      <B.Wrapper
        disabled={isLoading}
        onClick={() => { }}
        variant={variant}
        width={width}
        radius={radius}
      >
        {(icon && !isLoading) && icon}
        {isLoading && <Oval height={14} width={14} />}
        {children}
      </B.Wrapper>
      {!isLoading && <B.TextArea>{label && <div className="label">{label}</div>}</B.TextArea>}
    </B.Body>
  );
};

export default Button;
