import { useEffect, useMemo, useState } from "react";
import { FiEdit, FiEye, FiTrash2 } from 'react-icons/fi';
import { IoMdAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import CardBox from "../../../components/CardBox";
import { CustomModal } from "../../../components/CustomModal";
import CustomTable from "../../../components/CustomTable";
import Filter from "../../../components/Filter";
import { StatusIndicator } from "../../../components/StatusIndicator";
import TokenForm from "../../../components/TokenForm";
import { api } from "../../../service/api";
import { DeleteButton, EditButton, ViewButton } from "./styel";
import { isDefault } from "../../../service/token.service";

export const ListTokens = () => {
    const navigation = useNavigate();
    const [servers, setServers] = useState<any>([]);
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);

    const fetchTokes = () => {
        setLoading(true);
        api.get('/ex/apis/').then(response => {
            setServers(response.data);
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleDelete = (id: number) => {
        api.delete(`/ex/apis/${id}/`).then(response => {
            console.log(response.data);
            setServers([...servers, ...response.data]);
            fetchTokes();
        }).catch(error => {
            console.error(error);
        });
    }
    

    const columns = useMemo(
        () => [
            {
                Header: 'Ativo',
                accessor: 'allowed',
                Cell: ({ row }: any) => (
                    <StatusIndicator status={row.original.allowed ? 'ligado' : 'desligado'} />
                )
            },
            {
                Header: 'Token',
                accessor: 'token',
            },
            {
                Header: 'Nome',
                accessor: 'name',
            },
            {
                Header: 'Criado Por',
                accessor: 'created_by',
            },
            {
                Header: 'Criado em',
                accessor: 'created_at',
            },
            {
                Header: 'Ações',
                Cell: ({ row }: any) => (
                    <div style={{ textAlign: 'center' }}>
                        <ViewButton onClick={() => {
                            localStorage.setItem('server_detail', row.original.id.toString());
                            navigation('/machines/detail');
                        }} title="Visualizar">
                            <FiEye />
                        </ViewButton>
                        <EditButton onClick={() => {
                            setTokenId(row.original.id);
                            setTokenName(row.original.name);
                            setIsActive(row.original.allowed);
                            setIsOpen(true);
                        }} title="Editar">
                            <FiEdit />
                        </EditButton>
                        <DeleteButton onClick={() => handleDelete(row.original.id)} title="Excluir">
                            <FiTrash2 />
                        </DeleteButton>
                    </div>
                )
            },
        ],
        []
    );

    useEffect(() => {
        fetchTokes();
    }, []);


    const filteredData = useMemo(() => {
        console.log(servers.filter((item: any) => {
            return Object.values(item).some((value) => {
                return String(value).toLowerCase().includes(searchValue.toLowerCase());
            });
        }))
        return servers.filter((item: any) => {
            return Object.values(item).some((value) => {
                return String(value).toLowerCase().includes(searchValue.toLowerCase());
            });
        });
    }, [servers, searchValue]);


    const [tokenId, setTokenId] = useState(null);
    const [tokenName, setTokenName] = useState('');
    const [isActive, setIsActive] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (tokenId) {
            api.patch(`/ex/apis/${tokenId}/`, {
                name: tokenName,
                allowed: isActive,
            }).then(response => {
                console.log(response.data);
                fetchTokes();
                setIsOpen(false);
            }).catch(error => {
                console.error(error);
            });
            setTokenId(null);
        }
        else {
            api.post('/ex/apis/', {
                name: tokenName,
                allowed: isActive,
                created_by: 1
            }).then(response => {
                setIsOpen(false);
                setServers([...servers, response.data]);
            }).catch(error => {
                console.error(error);
            });
        }

        console.log({ tokenName, isActive })
    };
    return (
        <>
            <CustomModal
                title="Criar um novo token"
                isOpen={isOpen}
                onClose={() => { setIsOpen(false) }}
                children={<TokenForm
                    handleSubmit={handleSubmit}
                    tokenName={tokenName}
                    setTokenName={setTokenName}
                    isActive={isActive}
                    setIsActive={setIsActive}
                />}
            />
            <Filter setValue={setSearchValue} value={searchValue} placeholder="Pesquise por IP, Status, Nome, Data de Criação e etc.." />
            <CardBox
                title="Lista de Tokens"
                buttonChildren={<Button variant="primary" onClick={() => { setIsOpen(true) }}><IoMdAddCircleOutline />Adicionar Token</Button>}
                content={
                    <CustomTable maxHeight="calc(100vh - 300px)"
                        isLoading={loading}
                        columns={columns}
                        data={filteredData}
                        columnMinWidths={{}} />
                }
            />
        </>
    );
};