// RoutesConfig.tsx
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Navbar, { SideBarData, SidebarItem } from "./components/Navbar";
import Console from "./Console/Console";
import { Dashboard } from "./pages/Dashboard";
import { Hosts } from "./pages/Hosts";
import HostsDetail from "./pages/HostsDetail";
import Login from "./pages/Login";
import { ListMachines } from "./pages/Machine/ListMachine";
import MachileDetail from "./pages/MachineDetail";
import { Wrapper } from "./pages/MachineDetail/styles";
import { ListTokens } from "./pages/Token/ListTokens";
import { ListUsers } from "./pages/Users/ListUsers";
import { PageStructure } from "./styled/style";
import { getUserProfile } from "./service/token.service";

const hasRoutePermission = (path: string, userProfile: string, sidebarData: SidebarItem[]): boolean => {
  const flatRoutes = sidebarData.flatMap(item => 
    item.subItems ? [item, ...item.subItems] : [item]
  );
  const route = flatRoutes.find(item => item.path === path);
  
  if (!route) {
    return false;
  }
  if (!route.profiles || route.profiles.length === 0) {
    return true;
  }
  return route.profiles.includes(userProfile);
};

const RoutesConfig = () => {
  const PrivateRoutes = () => {
    const location = useLocation();
    const logged = localStorage.getItem("accessToken");
    const userProfile = getUserProfile();

    if (!logged) {
      toast.error('Sua sessão expirou!');
      return <Navigate to="/" replace state={{ from: location }} />;
    }

    if (!hasRoutePermission(location.pathname, userProfile, SideBarData)) {
      toast.error('Você não tem permissão para acessar esta página!');
      return <Navigate to="/dashboard" replace />;
    }

    return (
      <PageStructure>
        <Navbar />
        <Wrapper>
          <Outlet />
        </Wrapper>
      </PageStructure>
    );
  };

  const PrivateRouteNoWrapper = () => {
    const location = useLocation();
    const logged = localStorage.getItem("accessToken");
    const userProfile = getUserProfile();

    if (!logged) {
      toast.error('Sua sessão expirou!');
      return <Navigate to="/" replace state={{ from: location }} />;
    }

    if (!hasRoutePermission(location.pathname, userProfile, SideBarData)) {
      toast.error('Você não tem permissão para acessar esta página!');
      return <Navigate to="/dashboard" replace />;
    }

    return <Outlet />;
  };

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index={true} Component={Login} path="/" />
          <Route Component={PrivateRoutes} path="/dashboard">
            <Route Component={Dashboard} path="/dashboard" />
          </Route>
          <Route Component={PrivateRouteNoWrapper} path="/console">
            <Route Component={Console} path="/console" />
          </Route>
          <Route Component={PrivateRoutes} path="/machines/detail">
            <Route Component={MachileDetail} path="/machines/detail" />
          </Route>
          <Route Component={PrivateRoutes} path="/machines">
            <Route Component={ListMachines} path="/machines" />
          </Route>
          <Route Component={PrivateRoutes} path="/hosts">
            <Route Component={Hosts} path="/hosts" />
          </Route>
          <Route Component={PrivateRoutes} path="/hosts/detail">
            <Route Component={HostsDetail} path="/hosts/detail" />
          </Route>
          <Route Component={PrivateRoutes} path="/tokens">
            <Route Component={ListTokens} path="/tokens" />
          </Route>
          <Route Component={PrivateRoutes} path="/users">
            <Route Component={ListUsers} path="/users" />
          </Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </>
  );
};

export default RoutesConfig;