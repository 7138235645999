// Navbar.tsx
import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import { FaClipboardUser } from "react-icons/fa6";
import { MdOutlineIntegrationInstructions } from "react-icons/md";
import { ReactComponent as LogoSVG } from "../../assets/img/LogoAzul.svg";
import * as S from "./Sidebar.styles";
import { getUserProfile, hasPermission } from "../../service/token.service";

export interface SidebarItem {
  title: string;
  path: string;
  icon: React.ReactNode;
  subItems?: SidebarItem[];
  key?: string;
  profiles?: string[];
  hideInMenu?: boolean;
}

export const SideBarData: SidebarItem[] = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <FaIcons.FaHouseUser />,
    profiles: [],
  },
  {
    title: "Hospedagem",
    path: "",
    icon: <FaIcons.FaCubes />,
    key: "machine",
    profiles: ["padrão", "viewer"],
    subItems: [
      {
        title: "VMs Genial",
        path: "/machines",
        icon: <FaIcons.FaCube />,
        profiles: ["padrão", "viewer"],
      },
    ],
  },
  {
    title: "Usuários",
    path: "/users",
    icon: <FaClipboardUser />,
    key: "Usuarios",
    profiles: ["padrão"],
  },
  {
    title: "Tokens",
    path: "/tokens",
    icon: <MdOutlineIntegrationInstructions />,
    key: "Tokens",
    profiles: ["padrão"],
  },
  {
    title: "Sair",
    path: "/",
    icon: <FaIcons.FaArrowCircleLeft />,
    profiles: [],
  },
  {
    title: "Console",
    path: "/console",
    icon: <FaIcons.FaTerminal />,
    profiles: ["padrão"],
    hideInMenu: true,
  },
  {
    title: "Detalhes da Máquina",
    path: "/machines/detail",
    icon: <FaIcons.FaInfoCircle />,
    profiles: ["padrão", "viewer"],
    hideInMenu: true,
  },
  {
    title: "Detalhes do Host",
    path: "/hosts/detail",
    icon: <FaIcons.FaServer />,
    profiles: ["padrão", "viewer"],
    hideInMenu: true,
  },
];

const Navbar = () => {
  const [activeSidebar, setActiveSidebar] = useState<string | null>(null);
  const userProfile = getUserProfile();

  const toggleSidebar = (item: SidebarItem) => {
    if (item.subItems) {
      console.log("Toggling:", item.title, "Current active:", activeSidebar, "New active:", activeSidebar === item.title ? null : item.title);
      setActiveSidebar(activeSidebar === item.title ? null : item.title);
    }
  };

  const filteredSideBarData = SideBarData.map(item => {
    if (item.hideInMenu) return null;

    if (item.subItems) {
      const filteredSubItems = item.subItems.filter(subItem => 
        !subItem.hideInMenu && hasPermission(subItem, userProfile)
      );
      // Mantém o item pai se ele tiver permissão ou se algum subitem for permitido
      if (filteredSubItems.length > 0 || hasPermission(item, userProfile)) {
        return { ...item, subItems: filteredSubItems };
      }
      return null;
    }

    // Itens sem subitens só aparecem se tiverem permissão
    return hasPermission(item, userProfile) ? item : null;
  }).filter(Boolean) as SidebarItem[];

  console.log("filteredSideBarData:", filteredSideBarData);

  return (
    <S.SidebarContainer className={activeSidebar ? "nav-menu active" : "nav-menu"}>
      <S.SidebarList className="nav-menu-items">
        <S.LogoWrapper>
          <LogoSVG />
        </S.LogoWrapper>
        <S.MenuItemsWrapper>
          {filteredSideBarData.map((item, index) => (
            <S.SidebarItem
              key={index}
              className="nav-text"
              onClick={() => {
                console.log("Clicked item:", item.title);
                toggleSidebar(item);
              }}
            >
              <S.SidebarLink to={item.path}>
                {item.icon}
                <span>{item.title}</span>
                {item.subItems && (
                  <S.ToggleIcon>
                    {activeSidebar === item.title ? (
                      <FaIcons.FaChevronUp />
                    ) : (
                      <FaIcons.FaChevronDown />
                    )}
                  </S.ToggleIcon>
                )}
              </S.SidebarLink>
              {item.subItems && item.subItems.length > 0 && (
                <S.SidebarSublist className={activeSidebar === item.title ? "active" : ""}>
                  {item.subItems.map((subItem, subIndex) => (
                    <S.SidebarSubItem key={subIndex}>
                      <S.SidebarLink to={subItem.path}>
                        {subItem.icon}
                        <span>{subItem.title}</span>
                      </S.SidebarLink>
                    </S.SidebarSubItem>
                  ))}
                </S.SidebarSublist>
              )}
            </S.SidebarItem>
          ))}
        </S.MenuItemsWrapper>
      </S.SidebarList>
    </S.SidebarContainer>
  );
};

export default Navbar;